.cameraDisplay {
  width: 100%;
  display: flex;
  margin: 0 auto;
  transform: rotateY(180deg);
}
.cameraNoDisplay {
  width: 100%;
  display: none;
  margin: 0 auto;
}

.deleteAccountContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switchCamera {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
}

.switchCamera .MuiInputLabel-root {
  margin-right: 10px;
  font-weight: bold;
}

.addOrgOption {
  display: flex;
  margin-top: 16px;
  align-items: center;
  justify-content: center;
}

.profileSettings {
  width: 50%;
  margin: 0 auto !important;
}

/* .rst__tree{
  height: 100% !important;
} */

.rst__virtualScrollOverride > div {
  position: unset !important;
}

.rst__rowContents {
  border-radius: 15px !important;
  box-shadow: 0px 3px 11px 2px rgb(0 0 0 / 10%) !important;
  border: none !important;
}
.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after,
.rst__lineChildren::after {
  background-color: #cfcfcf !important;
}

.rst__collapseButton,
.rst__expandButton {
  box-shadow: 0 0 0 2px #5569ff !important;
}

.minimap {
  right: 98px !important;
}

.tree-link {
  cursor: pointer;
}

.tree-link:hover {
  color: #5569ff;
  text-decoration: underline;
}

@media only screen and (max-width: 1400px) {
  .actionModal {
    height: calc(100vh - 220px);
    overflow: auto;
  }
}
.mui-tab .MuiTabs-indicator {
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0) !important;
  box-shadow: 0px 2px 10px rgba(136, 150, 255, 0) !important;
}
.mui-tab .Mui-selected,
.mui-tab .Mui-selected:hover {
  color: #020202c2 !important;
  z-index: 5;
  background-color: #5569ff59;
}
.mui-tab .MuiTabs-root {
  border: none !important;
}
@media (min-width: 1280px) {
  .customization-container .MuiContainer-root {
    max-width: 100% !important;
  }
}
.customization-container .MuiFormControlLabel-label {
  color: black;
  font-size: 16px;
}
.customization-container fieldset legend {
  display: none !important;
}
.customization-container fieldset {
  z-index: 999;
}
.customization-container::-webkit-scrollbar {
  display: none;
}
.transform-component-module_wrapper__SPB86 {
  overflow: visible !important;
}
.customization-color-picker {
  height: 53px;
  border-radius: 5px;
}
